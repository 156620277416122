<template>
  <!-- 会员中心 -->
  <div class="ybmxm">
    <h1 style="text-align: center">补贴项目审批</h1>

    <div class="project">
      <el-button
        style="position: absolute; top: 100px; right: 150px; z-index: 9"
        type="primary"
        icon="el-icon-arrow-left"
        @click="goback"
        >返回</el-button
      >
      <div class="table-card">
        <table>
          <tr style="text-align: center">
            <td rowspan="5">基础信息</td>
            <td>姓名</td>
            <td>
              {{
                tableData.subsidySocialServiceVo.applyUser
                  ? tableData.subsidySocialServiceVo.applyUser
                  : ""
              }}
            </td>
            <td>联系电话</td>
            <td>
              {{
                tableData.subsidySocialServiceVo.phone
                  ? tableData.subsidySocialServiceVo.phone
                  : ""
              }}
            </td>
          </tr>
          <tr style="text-align: center">
            <td>身份证号</td>
            <td colspan="3">
              {{
                tableData.subsidySocialServiceVo.idCard
                  ? tableData.subsidySocialServiceVo.idCard
                  : ""
              }}
            </td>
          </tr>
          <tr style="text-align: center">
            <td>地址</td>
            <td colspan="3">
              {{
                tableData.subsidySocialServiceVo.address
                  ? tableData.subsidySocialServiceVo.address
                  : ""
              }}
            </td>
          </tr>
          <tr style="text-align: center">
            <td rowspan="2">账户</td>
            <td>账户</td>
            <td colspan="2">
              {{
                tableData.subsidySocialServiceVo.bankCard
                  ? tableData.subsidySocialServiceVo.bankCard
                  : ""
              }}
            </td>
          </tr>
          <tr style="text-align: center">
            <td>开户行</td>
            <td colspan="2">
              {{
                tableData.subsidySocialServiceVo.bankName
                  ? tableData.subsidySocialServiceVo.bankName
                  : ""
              }}
            </td>
          </tr>
          <tr>
            <td colspan="5" style="padding: 0">
              <table style="border: 100px">
                <tr style="text-align: center">
                  <td>机耕面积(亩)</td>
                  <td>机种面积(亩)</td>
                  <td>病虫害统防统治面积(亩)</td>
                  <td>机收面积(亩)</td>
                </tr>
                <tr style="text-align: center">
                  <td>
                    <span v-if="str == 'xq'">{{
                      tableData.subsidySocialServiceVo.cultivatedArea
                        ? tableData.subsidySocialServiceVo.cultivatedArea
                        : "0"
                    }}</span>
                    <el-input-number
                      v-else
                      v-model="form.cultivatedArea"
                      :min="0"
                    ></el-input-number>
                  </td>
                  <td>
                    <span v-if="str == 'xq'">{{
                      tableData.subsidySocialServiceVo.aircraftArea
                        ? tableData.subsidySocialServiceVo.aircraftArea
                        : "0"
                    }}</span>
                    <el-input-number
                      v-else
                      v-model="form.aircraftArea"
                      :min="0"
                    ></el-input-number>
                  </td>
                  <td>
                    <div>
                      <span v-if="str == 'xq'">{{
                        tableData.subsidySocialServiceVo.diseaseControl
                          ? tableData.subsidySocialServiceVo.diseaseControl
                          : "0"
                      }}</span>
                      <el-input-number
                        v-else
                        v-model="form.diseaseControl"
                        :min="0"
                      ></el-input-number>
                    </div>
                    <span
                      >((其中兴村通平台服务{{
                        this.viewlist.diseaseControl
                          ? this.viewlist.diseaseControl
                          : "0"
                      }}亩))
                      <span style="color: #70b9e2" @click="showDetail"
                        >查看详情</span
                      ></span
                    >
                  </td>
                  <td>
                    <span v-if="str == 'xq'">{{
                      tableData.subsidySocialServiceVo.receiveArea
                        ? tableData.subsidySocialServiceVo.receiveArea
                        : "0"
                    }}</span>
                    <el-input-number
                      v-else
                      v-model="form.receiveArea"
                      :min="0"
                    ></el-input-number>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2" style="position: relative">
              <div style="height: 50%">
                村委会意见：
                <span style="font-weight: bold" v-if="type == 'yb'">{{
                  A1.content
                }}</span>
                <el-input
                  v-if="
                    (this.tableData.taskKey ==
                      'sid-d69a82b3-61bc-43aa-8fa1-d20607da68bd' ||
                      this.tableData.taskKey ==
                        'sid-578375e8-1f96-4106-991c-fa9c7cf84f96') &&
                    type == 'db'
                  "
                  type="textarea"
                  :rows="5"
                  placeholder="请输入内容"
                  v-model="idea"
                  @input="callApiOnBlur"
                  @blur="showOtherContent"
                >
                </el-input>
              </div>
              <div style="height: 50%; display: flex">
                负责人签字：
                <img
                  v-if="type == 'yb'"
                  :src="baseUrl + 'admin/file/get?ossFilePath=' + A1.pictureUrl"
                  alt=""
                  style="width: 20%; transform: rotate(270deg)"
                />
                <vue-qr
                  v-if="
                    showContent == true &&
                    (this.tableData.taskKey ==
                      'sid-d69a82b3-61bc-43aa-8fa1-d20607da68bd' ||
                      this.tableData.taskKey ==
                        'sid-578375e8-1f96-4106-991c-fa9c7cf84f96') &&
                    type == 'db'
                  "
                  ref="qrCode"
                  :text="text"
                  width="200"
                  height="200"
                ></vue-qr>
              </div>
            </td>
            <td colspan="3" style="position: relative">
              <div style="height: 50%">
                乡镇政府意见：
                <span
                  style="font-weight: bold"
                  v-if="type == 'yb' || A2.content"
                  >{{ A2.content }}</span
                >
                <el-input
                  v-if="
                    this.tableData.taskKey ==
                      'sid-b0f35310-7396-450b-84b3-eddad2c2e1d8' && type == 'db'
                  "
                  type="textarea"
                  :rows="5"
                  placeholder="请输入内容"
                  v-model="idea"
                  @input="callApiOnBlur"
                  @blur="showOtherContent"
                >
                </el-input>
              </div>
              <div style="height: 50%; display: flex">
                负责人签字：
                <img
                  v-if="type == 'yb'"
                  :src="baseUrl + 'admin/file/get?ossFilePath=' + A2.pictureUrl"
                  alt=""
                  style="width: 20%; transform: rotate(270deg)"
                />
                <vue-qr
                  ref="qrCode"
                  v-if="
                    showContent == true &&
                    this.tableData.taskKey ==
                      'sid-b0f35310-7396-450b-84b3-eddad2c2e1d8' &&
                    type == 'db'
                  "
                  :text="text"
                  width="200"
                  height="200"
                ></vue-qr>
              </div>
              <!-- <span style="position: absolute; bootom: -10px; right: 10px"
                >{{}}</span
              > -->
            </td>
          </tr>
          <tr>
            <td colspan="5">
              县农业农村局意见：
              <div>
                <span style="font-weight: bold" v-if="type == 'yb'">{{
                  A3.content
                }}</span>
                <el-input
                  v-if="
                    this.tableData.taskKey ==
                      'sid-afbb3b38-d52a-403f-a8fe-f0d2a21c3bb6' && type == 'db'
                  "
                  type="textarea"
                  :rows="5"
                  placeholder="请输入内容"
                  v-model="idea"
                  @input="callApiOnBlur"
                  @blur="showOtherContent"
                >
                </el-input>
                <div style="height: 50%; display: flex">
                  负责人签字：
                  <img
                    v-if="type == 'yb'"
                    :src="
                      baseUrl + 'admin/file/get?ossFilePath=' + A3.pictureUrl
                    "
                    alt=""
                    style="width: 20%; transform: rotate(270deg)"
                  />
                  <vue-qr
                    ref="qrCode"
                    v-if="
                      showContent == true &&
                      this.tableData.taskKey ==
                        'sid-afbb3b38-d52a-403f-a8fe-f0d2a21c3bb6' &&
                      type == 'db'
                    "
                    :text="text"
                    width="200"
                    height="200"
                  ></vue-qr>
                </div>
              </div>

              <div style="text-align: right; line-height: 40px">
                <!-- <span style="margin-right: 20%">负责人签字：</span> -->
                <span style="margin-right: 2%">年</span>
                <span style="margin-right: 2%">月</span>
                <span style="margin-right: 2%">日</span>
              </div>
            </td>
          </tr>
        </table>
        <!-- 相关附件 -->
        <h2 style="text-align: center">相关附件</h2>
        <hr />
        <div
          style="
            width: 50%;
            margin-top: 20px;
            margin: 0 auto;
            text-align: center;
            height: 500px;
          "
        >
          <!-- 身份证 -->
          <div style="display: flex; text-align: center; margin-bottom: 40px">
            <div>身份证照片<span style="color: red">*</span></div>
            <el-image
              style="width: 150px; height: 100px; margin-right: 10px"
              :src="
                baseUrl +
                'admin/file/get?ossFilePath=' +
                tableData.subsidySocialServiceVo.legalIdcardPicBack
              "
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
            <el-image
              style="width: 150px; height: 100px"
              :src="
                baseUrl +
                'admin/file/get?ossFilePath=' +
                tableData.subsidySocialServiceVo.legalIdcardPicFront
              "
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </div>
          <div style="display: flex; text-align: center; margin-bottom: 40px">
            <div style="margin-right: 30px">其他材料</div>
            <div>
              <el-image
                style="width: 100px; height: 100px; margin-right: 10px"
                v-for="(item, index) in tableData.subsidySocialServiceVo
                  .otherFiles"
                :key="index"
                :src="baseUrl + 'admin/file/get?ossFilePath=' + item.imgUrl"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </div>
          </div>
          <el-button v-if="str == 'xq'" type="danger" @click="onshow = true"
            >退回</el-button
          >
          <el-button v-if="str == 'xg'" type="primary" @click="save"
            >保存</el-button
          >
        </div>
      </div>
    </div>
    <el-dialog
      title="审批不通过"
      :visible.sync="onshow"
      width="30%"
      append-to-body
    >
      <!-- 展示上传的图片 -->
      <div class="tuihui">
        <div class="leftbox">退回说明</div>
        <div class="rightbox">
          <el-input
            type="textarea"
            :rows="8"
            placeholder="请输入内容"
            v-model="title"
          >
          </el-input>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onshow = false">取 消</el-button>
        <el-button type="primary" @click="reject">退 回</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="兴村通平台社会化服务记录"
      :visible.sync="dialogVisible1"
      width="80%"
    >
      <el-table
        ref="multipleTable"
        :data="viewlist.diseaseControlList"
        border
        tooltip-effect="dark"
        max-height="550"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          prop="areaName"
          label="区域"
          width="200px"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="center"
          label="托管类型"
          width="250px"
        >
          <template slot-scope="scope">{{
            scope.row.orderType == 1 ? "全程托管" : "环节托管"
          }}</template>
        </el-table-column>
        <el-table-column align="center" prop="cropCode" label="作物类型">
          <template slot-scope="scope">
            <span>{{ scope.row.list[0].cjTime }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="farmerName" label="托管人">
        </el-table-column>
        <el-table-column align="center" prop="landArea" label="面积(亩)">
        </el-table-column>
        <el-table-column align="center" prop="startDate" label="开始时间">
        </el-table-column>
        <el-table-column align="center" prop="endDate" label="结束时间">
        </el-table-column>
        <!-- <el-table-column align="center" label="操作">
          <template ><button>详情</button></template>
        </el-table-column> -->
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(dialogVisible1 = false), (viewlist = [])"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueQr from "vue-qr";
import {
  selectSocialServiceById,
  selectServiceArea,
  updateSocialServiceById,
  rejectWorkflow_redis,
  rejectWorkflow,
} from "@/api/snbt";
import { log } from "console";
const baseUrl = window.globalUrl.HOME_API;
const Url = window.globalUrl.api2;
import { Utils } from "@/assets/js/util.js";
export default {
  components: {
    VueQr,
  },
  data() {
    return {
      A3: {}, // 县级
      A2: {}, // 镇级
      A1: {}, // 村级
      showContent: false, // 失去焦点显示二维码
      form: {
        aircraftArea: "",
        cultivatedArea: "",
        diseaseControl: "",
        id: "",
        receiveArea: "",
      }, // 表单数据
      id: "", // 项目id
      str: "", //  xq 代表详情页 xg 代表修改页
      type: "", // 项目类型
      tableData: [], // 表格数据
      Area: {}, // 服务面积
      viewlist: [], // 查看详情列表
      title: "", // 退回原因
      idea: "", // 审批意见
      text: Url,
      baseUrl: baseUrl,
      key: "", // 二维码key
      showy: false, // 审批通过弹窗
      onshow: false, // 审批不通过弹窗
      dialogVisible1: false, // 详情弹窗
      // 相关附件
      imgUrl: ["", ""], //身份证人像页的本地图片url
      imgInfo: [undefined, undefined], //身份证人像页的上传返回数据
      fileList1: [], //身份证人像页的文件列表
      fileList: [],
      fileList2: [], //其他材料的文件列表
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  created() {
    var that = this;
    Utils.$off("dbspDetails");
    Utils.$on("dbspDetails", function (e) {
      console.log("44454", e.data);
      console.log(JSON.parse(e.data));
      let res = JSON.parse(e.data);
      if (res.isBoolean == true) {
        that.showy = false;
        this.goback();
      }
    });
    this.id = this.$route.query.id;
    this.str = this.$route.query.str;
    this.type = this.$route.query.type;
    console.log(this.id);
    this.getobj();
    this.redis();
  },
  methods: {
    showOtherContent() {
      this.showContent = true; // 失去焦点时显示内容
    },
    // 查看详情
    async getobj() {
      console.log("id", this.id);
      let res = await selectSocialServiceById({ id: this.id });
      if (res.code == 0) {
        res.data.subsidySocialServiceVo.otherFiles = res.data
          .subsidySocialServiceVo.otherFiles
          ? JSON.parse(res.data.subsidySocialServiceVo.otherFiles)
          : [];
        this.tableData = res.data;
        // 如果是修改的话赋值
        if (this.str == "xg") {
          this.form.aircraftArea =
            this.tableData.subsidySocialServiceVo.aircraftArea;
          this.form.cultivatedArea =
            this.tableData.subsidySocialServiceVo.cultivatedArea;
          this.form.diseaseControl =
            this.tableData.subsidySocialServiceVo.diseaseControl;
          this.form.id = this.tableData.id;
          this.form.receiveArea =
            this.tableData.subsidySocialServiceVo.receiveArea;
        }
        this.selectServiceArea();
      }

      this.tableData.twActivitySubsidyTaskHistories?.forEach((item) => {
        // 县级
        if (item.taskKey == "sid-afbb3b38-d52a-403f-a8fe-f0d2a21c3bb6") {
          this.A3 = item;
        } else if (item.taskKey == "sid-b0f35310-7396-450b-84b3-eddad2c2e1d8") {
          // 镇级
          this.A2 = item;
        } else if (
          item.taskKey == "sid-d69a82b3-61bc-43aa-8fa1-d20607da68bd" ||
          item.taskKey == "sid-578375e8-1f96-4106-991c-fa9c7cf84f96"
        ) {
          this.A1 = item;
        }
      });

      console.log(res);
    },
    // 保存
    async save() {
      let res = await updateSocialServiceById(this.form);
      if (res.code == 0) {
        this.$message.success("保存成功");
        this.goback();
      }
    },
    // 查看兴村通平台服务面积
    async selectServiceArea() {
      let res = await selectServiceArea({
        areaCode: this.tableData.areaCode,
        identityId: this.tableData.declareIdentityId,
      });
      if (res.code == 0) {
        this.viewlist = res.data;
      }
    },
    showDetail() {
      this.dialogVisible1 = true;
    },

    // 重新生成二维码
    callApiOnBlur() {
      let identityObj = JSON.parse(localStorage.getItem("identityObj"));
      // 提取所有的 taskIds
      this.text = `${Url}?key=${this.key}/identityId=${identityObj.content.identityId}/agreeReason=${this.idea}`;
      console.log(this.text);
    },

    // 拿到key
    async redis() {
      let res = await rejectWorkflow_redis([
        { taskId: this.tableData.taskId, declareId: this.tableData.id },
      ]);
      this.key = res.data.key;
      console.log(res.data);

      this.callApiOnBlur();
      // this.reject(this.obj1);
    },

    // 拒绝
    async reject() {
      let params = [
        {
          taskId: this.tableData.taskId,
          declareId: this.tableData.id,
          agreeReason: this.title,
        },
      ];
      let res = await rejectWorkflow(params);
      if (res.code == 0) {
        this.$message.success("操作成功");
        this.goback();
      } else {
        this.$message.error(res.msg);
      }
      console.log(res);
    },
    // 返回上一级
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.tongyi {
  box-sizing: border-box;

  .topbox {
    span {
      width: 30%;
      font-size: 20px;
      // font-weight: 400;
      color: #000;
    }

    input {
      width: 60%;
      font-size: 20px;
    }
  }

  .footerbox {
    width: 100%;
    text-align: center;
    margin-top: 20px;

    .img {
      width: 100%;
      height: 100%;
    }
  }
}

.tuihui {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #000;

  .leftbox {
    width: 20%;
    height: 200px;
    text-align: center;
    line-height: 200px;
    font-size: 22px;
    border-right: 1px solid #000;
  }

  .rightbox {
    width: 80%;
    // height: 200px;

    // border-right: 1px solid #000;
  }
}

.ybmxm {
  width: 100%;
  margin: 0 auto;

  table {
    width: 1200px;
    margin: 0 auto;
    border-collapse: collapse;

    td {
      width: 20%;
      padding: 10px;
      border: 1px solid #000;
    }
  }
}

.upload-box {
  /deep/.el-upload {
    width: 148px !important;
    height: 126px !important;

    img {
      width: 100%;
      height: 100%;
    }
  }

  // .el-upload-list--picture-card .el-upload-list__item {
  //   width: 301px;
  //   height: 261px;
  // }
}
</style>
